
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		name: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			required: true,
		},
	},
	setup() {
		function prizeDescription(desc: string) {
			return desc.replace(/\n/g, '<div class="mt-2">');
		}

		return {
			prizeDescription,
		};
	},
});
