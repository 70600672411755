
import ContactSection from '~/components/ContactSection.vue';
import HeroBanner from '~/components/HeroBanner.vue';
import IntroductionSection from '~/components/IntroductionSection.vue';
import NavigationBar from '~/components/NavigationBar.vue';
import OurTeamSection from '~/components/OurTeamSection.vue';
import PrizesSection from '~/components/PrizesSection.vue';
import QuestionsSection from '~/components/QuestionsSection.vue';
import SectionSeparator from '~/components/SectionSeparator.vue';
import SponsorsSection from '~/components/SponsorsSection.vue';

export default {
	name: 'App',
	components: {
		NavigationBar,
		HeroBanner,
		IntroductionSection,
		QuestionsSection,
		SponsorsSection,
		PrizesSection,
		OurTeamSection,
		ContactSection,
		SectionSeparator,
	},
};
