
import { computed, onMounted, onUnmounted, ref } from 'vue';

export default {
	props: {
		question: {
			type: String,
			required: true,
		},
		answer: {
			type: String,
			required: true,
		},
	},
	setup() {
		const answerEl = ref<HTMLDivElement>();
		const answerHeight = ref(0);

		function resizeAnswerHeight() {
			answerHeight.value = answerEl.value!.scrollHeight;
		}

		onMounted(() => {
			window.addEventListener('resize', () => {
				resizeAnswerHeight();
			});

			resizeAnswerHeight();
		});

		onUnmounted(() => {
			window.removeEventListener('resize', resizeAnswerHeight);
		});

		const isAnswerVisible = ref(false);

		const answerStyle = computed(() => ({
			height: isAnswerVisible.value ? `${answerHeight.value}px` : '0',
		}));

		function toggleAnswer() {
			isAnswerVisible.value = !isAnswerVisible.value;
		}

		return {
			answerEl,
			toggleAnswer,
			isAnswerVisible,
			answerStyle,
		};
	},
};
